export default class fetchData {
    static async Post(vUrl, vData) {
        var pResponse = await fetch(window.globalConfig.BASE_URL2 + vUrl, {
            method: 'POST',
            headers: {
                //'Accept': 'application/json, text/plain, */*',
                'Accept': '*/*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify(vData)
        });
        var pData = await pResponse.text();
        try {
            pData = JSON.parse(pData);
        } catch (error) {
        } 

        if (pResponse.ok)
            return pData;
        else
            throw pData;
    }

    static async Get(vUrl) {
        var pResponse = await fetch(window.globalConfig.BASE_URL2 + vUrl, {
            credentials: 'include'
        });
        const pData = await pResponse.json();
        return pData;
    }
}