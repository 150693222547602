import Loadings from 'components/Loadings/Loadings';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import fetchData from 'utils/fetchData';

export default function BeiResults() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(async () => {
        fetchData()
    }, []);

    return (
        <>
            {isLoading ? (
                <Loadings />
            ) : (
                <div className="">
                    {/* <div className="flex items-center justify-between">
                        <div class="inline-flex rounded-md shadow-sm" role="group">
                            <button
                                onClick={() =>
                                    navigate(`/background-investigation-results/${id}`, {
                                        replace: true,
                                    })
                                }
                                type="button"
                                className="inline-flex items-center px-4 py-2 text-sm font-medium border-r border-l text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                            >
                                Background Investigation
                            </button>
                            <button
                                onClick={() =>
                                    navigate(`/pen-test-results/${id}`, {
                                        replace: true,
                                    })
                                }
                                type="button"
                                className="inline-flex items-center px-4 py-2 text-sm font-medium border-r text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                            >
                                Pen Test
                            </button>
                            <button
                                onClick={() =>
                                    navigate(`/simulations-performance-results/${id}`, {
                                        replace: true,
                                    })
                                }
                                type="button"
                                className="inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-200 focus:z-10 focus:ring-2 bg-primary  text-white focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                                disabled
                            >
                                Simulation
                            </button>

                            <button
                                onClick={() =>
                                    navigate(`/bei-results/${id}`, {
                                        replace: true,
                                    })
                                }
                                type="button"
                                className="inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-200 focus:z-10 focus:ring-2 bg-primary  text-white focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                                disabled
                            >
                                BEI
                            </button>

                            <button
                                onClick={() =>
                                    navigate(`/psychological-results/${id}`, {
                                        replace: true,
                                    })
                                }
                                type="button"
                                className="inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-200 focus:z-10 focus:ring-2 bg-primary  text-white focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                                disabled
                            >
                                Psychological
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 pt-2">
                        <h1 className="font-semibold text-base">
                            Date Posted:{" "}
                            {postionDatePosted
                                ? new Date(postionDatePosted).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                })
                                : "N/A"}
                        </h1>
                    </div>
                    <div className="flex items-center gap-2 pb-2">
                        <h1 className="font-semibold text-base">
                            Applicant Deadline:{" "}
                            {postionDateDeadline
                                ? new Date(postionDateDeadline).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                })
                                : "N/A"}
                        </h1>
                    </div>
                    <div className="w-full max-h-[65vh] overflow-auto mt-2">
                        <div className="w-[100%]">
                            <section className="sticky top-0 z-99">
                                <div className="grid grid-cols-24 grid-rows-3 border">
                                    <div className="row-span-3 border bg-primary flex items-center justify-center h-full">
                                        <h1 className="text-center text-white">No.</h1>
                                    </div>
                                    <div className="col-span-6 row-span-3 border bg-primary flex items-center justify-center">
                                        <h1 className="text-center text-white">
                                            Name of Applicant
                                        </h1>
                                    </div>
                                    <div className="col-span-8 row-span-3  border  bg-primary flex items-center justify-center">
                                        <h1 className="text-center text-white">Position</h1>
                                    </div>
                                    <div className="col-span-3 row-span-3 border  bg-primary flex items-center justify-center">
                                        <h1 className="text-center text-white">
                                            Knowledgeable of the position applying for
                                        </h1>
                                    </div>
                                    <div className="col-span-3 row-span-3 border  bg-primary flex items-center justify-center">
                                        <h1 className="text-center text-white">
                                            Actual Performance
                                        </h1>
                                    </div>
                                    <div className="col-span-2 row-span-3 border  bg-primary flex items-center justify-center">
                                        <h1 className="text-center text-white">20% Earned</h1>
                                    </div>
                                    <div className="row-span-3 border bg-primary flex items-center justify-center h-full"></div>
                                </div>
                            </section>
                            <div className="grid grid-cols-24 grid-rows-1 gap-0">
                                {rows.map((item, index) => {
                                    const sums = questionRows?.groups?.map((group) => {
                                        const slicedArray = group.questions.slice(
                                            0,
                                            group.questions.length - 1
                                        );
                                        const updatedFirstArray = slicedArray.map((slice) => {
                                            const matchingItem = item.answers.find(
                                                (secondItem) => secondItem.questionID === slice.id
                                            );
                                            if (matchingItem) {
                                                return {
                                                    ...slice,
                                                    answerText: matchingItem.answerText
                                                        ? matchingItem.answerText
                                                        : "1",
                                                };
                                            }
                                            return {
                                                ...slice,
                                                answerText: "1",
                                            };
                                        });
                                        return {
                                            groupSum: updatedFirstArray.reduce(
                                                (groupAcc, answer) =>
                                                    groupAcc + parseInt(answer.answerText),
                                                0
                                            ),
                                        };
                                    });
                                    return (
                                        <>
                                            <div className="border">
                                                <input
                                                    type="text"
                                                    value={index + 1 ? index + 1 : ""}
                                                    onChange={(e) =>
                                                        updateRow(item.id, "visibleNumber", e)
                                                    }
                                                    className="w-full h-full resize-none border-none focus:ring-0 p-0 text-center"
                                                    disabled
                                                    placeholder="#"
                                                />
                                            </div>
                                            <div className="col-span-6 border">
                                                <input
                                                    type="text"
                                                    value={item.name ? item.name : ""}
                                                    onChange={(e) => updateRow(item.id, "name", e)}
                                                    className="w-full h-full resize-none border-none focus:ring-0"
                                                    disabled
                                                    placeholder="Enter Name"
                                                />
                                            </div>
                                            <div className="col-span-8 border">
                                                <input
                                                    type="text"
                                                    value={
                                                        item.positionApplied ? item.positionApplied : ""
                                                    }
                                                    onChange={(e) => updateRow(item.id, "position", e)}
                                                    className="w-full h-full resize-none border-none focus:ring-0"
                                                    disabled
                                                    placeholder="Enter Position"
                                                />
                                            </div>
                                            {questionRows?.groups?.map((group) => {
                                                const slicedArray = group.questions.slice(
                                                    0,
                                                    group.questions.length - 1
                                                );
                                                const updatedFirstArray = slicedArray.map((slice) => {
                                                    const matchingItem = item.answers.find(
                                                        (secondItem) => secondItem.questionID === slice.id
                                                    );
                                                    if (matchingItem) {
                                                        return {
                                                            ...slice,
                                                            answerText: matchingItem.answerText
                                                                ? matchingItem.answerText
                                                                : "0",
                                                        };
                                                    }
                                                    return slice;
                                                });
                                                return updatedFirstArray.map((question) => {
                                                    return (
                                                        <div className="col-span-3 border">
                                                            <input
                                                                type="text"
                                                                value={
                                                                    question.answerText
                                                                        ? question.answerText
                                                                        : "1"
                                                                }
                                                                onChange={(e) =>
                                                                    updateRow(
                                                                        item.id,
                                                                        `${question.answerText}`,
                                                                        e
                                                                    )
                                                                }
                                                                disabled
                                                                className="w-full h-full resize-none border-none focus:ring-0 p-0 text-center"
                                                                placeholder="#"
                                                            />
                                                        </div>
                                                    );
                                                });
                                            })}
                                            <div className="col-span-2 border">
                                                <input
                                                    type="input"
                                                    value={
                                                        sums?.reduce(
                                                            (groupAcc, answer) => groupAcc + answer.groupSum,
                                                            0
                                                        )
                                                            ? sums?.reduce(
                                                                (groupAcc, answer) =>
                                                                    groupAcc + answer.groupSum,
                                                                0
                                                            ) *
                                                            2 +
                                                            "%"
                                                            : "0"
                                                    }
                                                    onChange={(e) => updateRow(item.id, "earned", e)}
                                                    className="w-full h-full resize-none border-none focus:ring-0 p-1 text-center"
                                                    disabled
                                                    placeholder="Enter Earned"
                                                />
                                            </div>
                                            <div className="border flex items-center justify-center">
                                                <Tooltip text="Edit">
                                                    <button>
                                                        <FaEdit
                                                            onClick={() =>
                                                                navigate(
                                                                    `/edit-simulations-performance-sheet/${id}/${item.answeredByID}`
                                                                )
                                                            }
                                                            className="w-5 h-5"
                                                        />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div> */}
                </div>
            )}
        </>
    );
}
