import React from "react";

const Loadings = () => {
  return (
    <div className="bg-transparent ">
      <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-800 border-opacity-100"></div>
      </div>
    </div>
  );
};

export default Loadings;
